const processPlayerList = (v) => {
	const response = JSON.parse(JSON.stringify(v))
	if (!response.data) return null;

	let defaultArray = [];

	const data = [...response.data.playerGgr];
	data.forEach(el => {
		const find = response.data.playerNetCashflow.find(netCash => netCash.player_id === el.player_id);
		if (find) {
			el.net_cashflow = find.net_cashflow;
			el.created_at = find.created_at;
		}
	});

	response.data = data;

	if (response.data.length === 0) {
		response.total = 1;
	}

	if (response.data.length < 10) {
		defaultArray = Array.from({ length: 10 - response.data.length }, () => ({
			player_id: '-', created_at: '-', ggr: '-', net_cashflow: '-', total_turnover: '-'
		}));
		response.data = response.data.concat(defaultArray);
	}

	response.total = ~~response.total;
	return response;
};
export const state = () => ({
	summaryOfDeposit: {},
	summaryOfWithdrawal: {},
	total: {
		player: {},
		client_quota: {},
		cost_and_income: {}
	},
	agentFeeTableData: [],
	marketingPriceTableData: [],
	summaryOfBannerWithdrawal: {
		top_banner_associate_player: [],
		top_banner_direct_player: [],
	},
	summaryOfBannerDeposit: {
		top_banner_associate_player: [],
		top_banner_direct_player: [],
	},
	newPlayerData: {},
	newPlayerBannerDirect: [],
	newPlayerBannerAssociate: [],
	regularPlayerData: {},
	regularPlayerBannerDirect: [],
	regularPlayerBannerAssociate: [],
	retainedPlayerData: {},
	retainedPlayerBannerDirect: [],
	retainedPlayerBannerAssociate: [],
	depositCount: {},
	withdrawalCount: {},
	manualDepositAdjustment: [],
	manualWithdrawalAdjustment: [],
	activePlayerCount: {},
	listOfPlayerDeposits: { data: [], total: 0, total_pages: 0 },
	listOfPlayerWithdrawal: { data: [], total: 0, total_pages: 0 },
	playerWinList: {
		data: []
	},
	playerLostList: {
		data: []
	},
	// Cancel token
	statsCancelToken: {},
	loadings: {
		getSummaryOfDeposit: false,
		getSummaryOfWithdrawal: false,
		getTotals: false,
		getAgentFee: false,
		getMarketingPrice: false,
		getSummaryBannerWithdrawal: false,
		getSummaryBannerDeposit: false,
		getNewPlayerReport: false,
		getNewPlayerBannerReport: false,
		getDepositCount: false,
		getWithdrawalCount: false,
		getRegularPlayerReport: false,
		getRegularPlayerBannerReport: false,
		getRetainedPlayerReport: false,
		getRetainedPlayerBannerReport: false,
		getRetainedPlayerBannerReportDirect: false,
		getRetainedPlayerBannerReportAssociate: false,
		getManualDepositAdjustment: false,
		getManualWithdrawalAdjustment: false,
		getActivePlayerCount: false,
		getDepositPlayerList: false,
		getWithdrawalPlayerList: false,
	},
	filterCache: {}
})
export const actions = {
	async getSummaryOfDeposit({ commit, state }, params) {
		commit('setLoadings', { getSummaryOfDeposit: true })
		commit('setStatsCancelToken', { summaryOfDeposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-deposit`, { params, cancelToken: state.statsCancelToken.summaryOfDeposit.token })
			.then((response) => {
				commit('setSummaryOfDeposit', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getSummaryOfDeposit: false })
	},
	async getSummaryOfWithdrawal({ commit, state }, params) {
		commit('setLoadings', { getSummaryOfWithdrawal: true })
		commit('setStatsCancelToken', { summaryOfWithdrawal: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-withdraw`, { params, cancelToken: state.statsCancelToken.summaryOfWithdrawal.token })
			.then((response) => {
				commit('setSummaryOfWithdrawal', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getSummaryOfWithdrawal: false })
	},
	async getTotals({ commit, state }, params) {
		commit('setLoadings', { getTotals: true })
		commit('setStatsCancelToken', { total: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/totals`, { params, cancelToken: state.statsCancelToken.total.token })
			.then((response) => {
				commit('setTotal', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getTotals: false })
	},
	async getAgentFee({ commit, state }, params) {
		commit('setLoadings', { getAgentFee: true })
		commit('setStatsCancelToken', { agentFee: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/agent-fee`, { params, cancelToken: state.statsCancelToken.agentFee.token })
			.then((response) => {
				commit('setAgentFeeTableData', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getAgentFee: false })
	},
	async getMarketingPrice({ commit, state }, params) {
		commit('setLoadings', { getMarketingPrice: true })
		commit('setStatsCancelToken', { marketingPrice: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/marketing-price`, { params, cancelToken: state.statsCancelToken.marketingPrice.token })
			.then((response) => {
				commit('setMarketingPriceTableData', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getMarketingPrice: false })
	},
	async getSummaryBannerWithdrawal({ commit, state }, params) {
		commit('setLoadings', { getSummaryBannerWithdrawal: true })
		commit('setStatsCancelToken', { bannerWithdrawal: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-banner-withdraw`, { params, cancelToken: state.statsCancelToken.bannerWithdrawal.token })
			.then((response) => {
				commit('setSummaryOfBannerWithdrawal', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getSummaryBannerWithdrawal: false })
	},
	async getSummaryBannerDeposit({ commit, state }, params) {
		commit('setLoadings', { getSummaryBannerDeposit: true })
		commit('setStatsCancelToken', { bannerDeposit: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/summary-of-banner-deposit`, { params, cancelToken: state.statsCancelToken.bannerDeposit.token })
			.then((response) => {
				commit('setSummaryOfBannerDeposit', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getSummaryBannerDeposit: false })
	},
	async getNewPlayerReport({ commit, state }, params) {
		commit('setLoadings', { getNewPlayerReport: true })
		commit('setStatsCancelToken', { newPlayerReport: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/new-player-report`, { params, cancelToken: state.statsCancelToken.newPlayerReport.token })
			.then((response) => {
				commit('setNewPlayerData', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getNewPlayerReport: false })
	},
	async getNewPlayerBannerReport({ commit, state }, params) {
		commit('setLoadings', { getNewPlayerBannerReport: true })
		commit('setStatsCancelToken', { newPlayerBannerReport: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/banner-new-player`, { params, cancelToken: state.statsCancelToken.newPlayerBannerReport.token })
			.then((response) => {
				if (params.banner_status === 2) {
					commit('setNewPlayerBannerDirect', response.data)
				} else {
					commit('setNewPlayerBannerAssociate', response.data)
				}
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getNewPlayerBannerReport: false })
	},
	async getDepositCount({ commit, state }, params) {
		commit('setLoadings', { getDepositCount: true })
		commit('setStatsCancelToken', { depositCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/deposit-count-player-type-and-promotion`, { params, cancelToken: state.statsCancelToken.depositCount.token })
			.then((response) => {
				commit('setDepositCount', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getDepositCount: false })
	},
	async getWithdrawalCount({ commit, state }, params) {
		commit('setLoadings', { getWithdrawalCount: true })
		commit('setStatsCancelToken', { withdrawalCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/withdraw-count-player-type-and-promotion`, { params, cancelToken: state.statsCancelToken.withdrawalCount.token })
			.then((response) => {
				commit('setWithdrawalCount', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getWithdrawalCount: false })
	},
	async getRegularPlayerReport({ commit, state }, params) {
		commit('setLoadings', { getRegularPlayerReport: true })
		commit('setStatsCancelToken', { regularPlayerReport: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/regular-player-report`, { params, cancelToken: state.statsCancelToken.regularPlayerReport.token })
			.then((response) => {
				commit('setRegularPlayerData', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getRegularPlayerReport: false })
	},
	async getRegularPlayerBannerReport({ commit, state }, params) {
		commit('setLoadings', { getRegularPlayerBannerReport: true })
		commit('setStatsCancelToken', { regularPlayerBannerReport: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/banner-regular-player`, { params, cancelToken: state.statsCancelToken.regularPlayerBannerReport.token })
			.then((response) => {
				if (params.banner_status === 2) {
					commit('setRegularPlayerBannerDirect', response.data)
				} else {
					commit('setRegularPlayerBannerAssociate', response.data)
				}
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getRegularPlayerBannerReport: false })
	},
	async getRetainedPlayerReport({ commit, state }, params) {
		commit('setLoadings', { getRetainedPlayerReport: true })
		commit('setStatsCancelToken', { retainedPlayerReport: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/retained-player-report`, { params, cancelToken: state.statsCancelToken.retainedPlayerReport.token })
			.then((response) => {
				commit('setRetainedPlayerData', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getRetainedPlayerReport: false })
	},
	async getRetainedPlayerBannerReport({ commit, state }, params) {
		if (params.banner_status === 2) {
			commit('setLoadings', { getRetainedPlayerBannerReportDirect: true })
			commit('setStatsCancelToken', { newPlayerBannerReportDirect: this.$axios.CancelToken.source() })
		} else {
			commit('setLoadings', { getRetainedPlayerBannerReportAssociate: true })
			commit('setStatsCancelToken', { newPlayerBannerReportAssociate: this.$axios.CancelToken.source() })
		}
		await this.$axios
			.get(`/statistics/all-summary/banner-retained-player`, {
				params, cancelToken:
					params.banner_status === 2 ? state.statsCancelToken.newPlayerBannerReportDirect.token : state.statsCancelToken.newPlayerBannerReportAssociate.token
			})
			.then((response) => {
				if (params.banner_status === 2) {
					commit('setRetainedPlayerBannerDirect', response.data)
				} else {
					commit('setRetainedPlayerBannerAssociate', response.data)
				}
			}).catch(_ => {
				return false
			})
		if (params.banner_status === 2) {
			commit('setLoadings', { getRetainedPlayerBannerReportDirect: false })
		} else {
			commit('setLoadings', { getRetainedPlayerBannerReportAssociate: false })
		}
	},
	async getManualDepositAdjustment({ commit, state }, params) {
		commit('setLoadings', { getManualDepositAdjustment: true })
		commit('setStatsCancelToken', { manualDepositAdjustment: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/manual-deposit-adjustment`, { params, cancelToken: state.statsCancelToken.manualDepositAdjustment.token })
			.then((response) => {
				commit('setManualDepositAdjustment', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getManualDepositAdjustment: false })
	},
	async getManualWithdrawalAdjustment({ commit, state }, params) {
		commit('setLoadings', { getManualWithdrawalAdjustment: true })
		commit('setStatsCancelToken', { manualWithdrawalAdjustment: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/manual-withdraw-adjustment`, { params, cancelToken: state.statsCancelToken.manualWithdrawalAdjustment.token })
			.then((response) => {
				commit('setManualWithdrawalAdjustment', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getManualWithdrawalAdjustment: false })
	},
	async getActivePlayerCount({ commit, state }, params) {
		commit('setLoadings', { getActivePlayerCount: true })
		commit('setStatsCancelToken', { activePlayerCount: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/count-active-player`, { params, cancelToken: state.statsCancelToken.activePlayerCount.token })
			.then((response) => {
				commit('setActivePlayerCount', response.data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getActivePlayerCount: false })
	},
	async getDepositPlayerList({ commit, state }, params) {
		commit('setStatsCancelToken', { depositPlayerList: this.$axios.CancelToken.source() })
		commit('setLoadings', { getDepositPlayerList: true })
		await this.$axios
			.get(`/statistics/all-summary/player-deposit`, { params, cancelToken: state.statsCancelToken.depositPlayerList.token })
			.then((response) => {
				const data = {
					total: response.data.total,
					total_pages: response.data.total_pages,
					data: response.data.data
				}
				if (data?.data?.length < 10) {
					data.total = 10
					for (let i = data.data.length + 1; i <= 10; i++) {
						data.data[i] = { player_id: "-", total_deposit_amount: "-", deposit_count: "-", created_at: "-", source_name: "-" }
					}
				}
				commit('setListOfPlayerDeposits', data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getDepositPlayerList: false })
	},
	async getWithdrawalPlayerList({ commit, state }, params) {
		commit('setLoadings', { getWithdrawalPlayerList: true })
		commit('setStatsCancelToken', { withdrawalPlayerList: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/statistics/all-summary/player-withdraw`, { params, cancelToken: state.statsCancelToken.withdrawalPlayerList.token })
			.then((response) => {
				const data = {
					total: response.data.total,
					total_pages: response.data.total_pages,
					data: response.data.data
				}
				if (data?.data?.length < 10) {
					data.total = 10
					for (let i = data.data.length + 1; i <= 10; i++) {
						data.data[i] = { player_id: "-", total_withdraw_amount: "-", withdraw_count: "-", created_at: "-" }
					}
				}
				commit('setListOfPlayerWithdrawal', data)
			}).catch(_ => {
				return false
			})
		commit('setLoadings', { getWithdrawalPlayerList: false })
	},
	async getPlayerWinLose({ commit, state }, param) {
		const params = {
			type: param.type,
			...param
		}
		if (param.type === 'win') {
			commit('setStatsCancelToken', { playerWinning: this.$axios.CancelToken.source() })
		}
		if (param.type === 'lose') {
			commit('setStatsCancelToken', { playerLosing: this.$axios.CancelToken.source() })
		}

		await this.$axios
			.get(`/statistics/all-summary/player-win-lose`, {
				params,
				cancelToken: param.type === 'win' ? state.statsCancelToken.playerWinning.token : state.statsCancelToken.playerLosing.token
			})
			.then((response) => {
				if (params.type === 'lose') {
					commit('setPlayerLostList', response.data)
				} else {
					commit('setPlayerWinList', response.data)
				}
			})
	}
}
export const mutations = {
	setSummaryOfDeposit(state, data) {
		state.summaryOfDeposit = data
	},
	setSummaryOfWithdrawal(state, data) {
		state.summaryOfWithdrawal = data
	},
	setTotal(state, data) {
		state.total = data
	},
	setAgentFeeTableData(state, data) {
		state.agentFeeTableData = data
	},
	setMarketingPriceTableData(state, data) {
		state.marketingPriceTableData = data
	},
	setSummaryOfBannerWithdrawal(state, data) {
		state.summaryOfBannerWithdrawal = data
	},
	setSummaryOfBannerDeposit(state, data) {
		state.summaryOfBannerDeposit = data
	},
	setNewPlayerData(state, data) {
		state.newPlayerData = data
	},
	setNewPlayerBannerDirect(state, data) {
		state.newPlayerBannerDirect = data
	},
	setNewPlayerBannerAssociate(state, data) {
		state.newPlayerBannerAssociate = data
	},
	setDepositCount(state, data) {
		state.depositCount = data
	},
	setWithdrawalCount(state, data) {
		state.withdrawalCount = data
	},
	setRegularPlayerData(state, data) {
		state.regularPlayerData = data
	},
	setRegularPlayerBannerDirect(state, data) {
		state.regularPlayerBannerDirect = data
	},
	setRegularPlayerBannerAssociate(state, data) {
		state.regularPlayerBannerAssociate = data
	},
	setRetainedPlayerData(state, data) {
		state.retainedPlayerData = data
	},
	setRetainedPlayerBannerDirect(state, data) {
		state.retainedPlayerBannerDirect = data
	},
	setRetainedPlayerBannerAssociate(state, data) {
		state.retainedPlayerBannerAssociate = data
	},
	setManualDepositAdjustment(state, data) {
		state.manualDepositAdjustment = data
	},
	setManualWithdrawalAdjustment(state, data) {
		state.manualWithdrawalAdjustment = data
	},
	setActivePlayerCount(state, data) {
		state.activePlayerCount = data
	},
	setListOfPlayerDeposits(state, data) {
		state.listOfPlayerDeposits = data
	},
	setListOfPlayerWithdrawal(state, data) {
		state.listOfPlayerWithdrawal = data
	},
	setPlayerWinList: (state, v) => {
		state.playerWinList = processPlayerList(v)
	},
	setPlayerLostList: (state, v) => {
		state.playerLostList = processPlayerList(v)
	},
	setStatsCancelToken: (state, data) => {
		const oldData = { ...state.statsCancelToken }
		state.statsCancelToken = {
			...oldData, ...data
		}
	},
	setLoadings: (state, v) => {
		state.loadings = { ...state.loadings, ...v }
	},
	setFilterCache: (state, v) => {
		state.filterCache = v
	}
}

export const getters = {
	totalDepositAmountForMainOperator: (state) => {
		return (
			parseFloat(state.summaryOfDeposit.total_deposit_main_operator || 0) -
			parseFloat(
				state.summaryOfDeposit.total_main_operator_ma_error_amount || 0
			)
		)
	},
	totalOperatorMAErrorAmount: (state) => {
		return (
			parseFloat(
				state.summaryOfDeposit.total_main_operator_ma_error_amount || 0
			) +
			parseFloat(
				state.summaryOfDeposit.total_all_sub_admin_ma_error_amount || 0
			)
		)
	},
	totalDepositAmount: (state, getters) => {
		return (
			parseFloat(state.summaryOfDeposit.total_all_deposit || 0) -
			getters.totalOperatorMAErrorAmount
		)
	},
	totalDepositAmountForSubOperator: (state) => {
		return (
			parseFloat(state.summaryOfDeposit.total_deposit_sub_admin || 0) -
			parseFloat(
				state.summaryOfDeposit.total_all_sub_admin_ma_error_amount || 0
			)
		)
	}
}